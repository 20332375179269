export const Blog = (props) => {
  return (
    <div id="blog">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/blog_.png" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>BLOG</h2>
			     <h3>Top 6 Content Marketing Tactics for Small Businesses to Drive Engagement and Growth</h3>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <a
                  href='https://quantumdesigners.com/site/' target='_blank' rel='noreferrer'
                  className='btn btn-custom btn-lg page-scroll'
                >
                  Read More ..
                </a>{' '}
             
            </div>
          </div>
        </div>
</div>
	</div>
	
  );
};
